'use client';

import { Button } from '@/design-system-components/button/button';

import Drawer, {
  drawerModalClass,
} from '@/design-system-components/drawer/drawer';
import { useTranslations } from 'next-intl';
import { DialogTrigger } from 'react-aria-components';

import { ControlledDrawerWrapper } from '@/design-system-components/drawer/controlled-drawer-wrapper';
import { cn } from '@/utils/tailwind';
import { Icon } from './icon';

export interface UnexpectedErrorDialogProps {
  open: boolean;
  message: string | null;
  buttonText: string | null;
  htmlContent: string | null;
  onClose: () => void;
}

export function UnexpectedErrorDialog(props: UnexpectedErrorDialogProps) {
  const { open, message, buttonText, htmlContent, onClose } = props;

  const t = useTranslations('unexpectedErrorDialog');

  const unexpectedErrorText = t('unexpectedError');
  const errorMessageText = t.rich('errorMessage_br', {
    br: () => <br />,
  });
  const tryAgainText = t('tryAgain');

  return (
    <DialogTrigger isOpen={open}>
      <Drawer
        desktopVariant="modal"
        className="flex items-center"
        modalClassName={cn(
          drawerModalClass.animation,
          'w-full max-w-[600px] rounded-lg bg-neutral-100 px-8 py-10 lg:p-10',
          'relative lg:fixed',
        )}
        dialogClassName="flex w-full flex-col"
        data-testid="unexpected-error-dialog"
        variant="modal"
      >
        {({ close }) => (
          <ControlledDrawerWrapper onClose={onClose}>
            <div className="absolute right-4 top-5 lg:right-6">
              <Button
                data-testid="drawer-close-button"
                variant="noBackground"
                size="icon"
                onPress={() => {
                  close();
                  onClose();
                }}
              >
                <Icon className="h-5" name="xmark" />
              </Button>
            </div>
            <div className="flex flex-col gap-6">
              <div className="flex flex-col items-start gap-6 lg:flex-row lg:items-center">
                <Icon
                  name="triangle-exclamation"
                  className="h-8 w-8 self-start text-lg text-error"
                />
                <span
                  className="font-heading text-2xl-bold md:text-3xl-bold"
                  data-testid="unexpected-error-dialog-modal-title"
                >
                  {unexpectedErrorText}
                </span>
              </div>
              <div className="mb-8">
                <span>
                  {htmlContent ? (
                    <p dangerouslySetInnerHTML={{ __html: htmlContent }} />
                  ) : (
                    message ?? errorMessageText
                  )}
                </span>
              </div>
            </div>
            <div className="flex justify-end">
              <Button
                variant="primary"
                className="mb-1 mr-1 px-12 py-4"
                onPress={onClose}
                fullWidth="mobile"
              >
                {buttonText ?? tryAgainText}
              </Button>
            </div>
          </ControlledDrawerWrapper>
        )}
      </Drawer>
    </DialogTrigger>
  );
}
