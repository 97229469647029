'use client';

import { useTranslations } from 'next-intl';

import { useRouter } from '@/i18n/navigation';

import { Button } from '@/design-system-components/button/button';
import Drawer, {
  drawerModalClass,
} from '@/design-system-components/drawer/drawer';
import { useLoyaltyCurrency } from '@/hooks/bootstrap/bootstrap-hooks';
import { DialogTrigger } from 'react-aria-components';

import { ControlledDrawerWrapper } from '@/design-system-components/drawer/controlled-drawer-wrapper';
import { cn } from '@/utils/tailwind';
import { Icon } from './icon';

export interface IneligibleRedeemErrorDialogProps {
  open: boolean;
  onClose: () => void;
}

export function IneligibleRedeemErrorDialog(
  props: IneligibleRedeemErrorDialogProps,
) {
  const t = useTranslations('ineligibleRedeemDialog');

  const loyaltyCurrency = useLoyaltyCurrency();
  const router = useRouter();
  const { open, onClose } = props;

  const ineligibleToRedeemText = t('ineligibleToRedeem');
  const sorryText = loyaltyCurrency?.name
    ? t('sorry_currency', {
        currency: loyaltyCurrency.name,
      })
    : '';
  const assistanceText = t('assistance');
  const goToHelpCenterText = t('goToHelpCenter');

  return (
    <DialogTrigger isOpen={open}>
      <Drawer
        desktopVariant="modal"
        className="flex items-center"
        modalClassName={cn(
          drawerModalClass.animation,
          drawerModalClass.dimensions,
          'relative lg:fixed',
        )}
        dialogClassName="flex w-full flex-col"
        data-testid="ineligible-redeem-dialog"
        variant="modal"
      >
        {({ close }) => (
          <ControlledDrawerWrapper onClose={onClose}>
            <div className="absolute right-4 top-5 lg:right-10">
              <Button
                data-testid="drawer-close-button"
                variant="noBackground"
                size="icon"
                onPress={() => {
                  close();
                  onClose();
                }}
              >
                <Icon className="h-5" name="xmark" />
              </Button>
            </div>
            <div className="mb-8 flex flex-col gap-6 lg:flex-row lg:items-center">
              <Icon name="user-xmark" className="h-8 w-8 text-lg text-error" />
              <span
                className="font-heading text-2xl-bold md:text-3xl-bold"
                data-testid="ineligible-redeem-dialog-modal-title"
              >
                {ineligibleToRedeemText}
              </span>
            </div>
            <div className="mb-8">
              <span>{sorryText}</span>
            </div>
            <div className="mb-8">
              <span>{assistanceText}</span>
            </div>
            <div className="flex justify-end">
              <Button
                variant="primary"
                className="mb-1 mr-1 px-12 py-4"
                onPress={() => {
                  router.push('/help-center');
                  onClose();
                }}
              >
                {goToHelpCenterText}
              </Button>
            </div>
          </ControlledDrawerWrapper>
        )}
      </Drawer>
    </DialogTrigger>
  );
}
